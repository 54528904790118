<template>
  <div class="jump">
    <!-- <p style="24px">
      正在跳转到支付页面，请稍候~
    </p> -->
    <img src="@/assets/images/loading.gif" alt="" />
  </div>
</template>

<script>
// import MyDialog from "./../dialog/dialog";  // 添加备注弹出的对话框
// import Keyboard from "./../keyboard/keyboard";  // 手写键盘
import {getPayOrder}from '@/api/api'
import wayCode from "@/utils/wayCode";
import config from "@/config";
export default {

  mounted() {
    console.log("客户端", wayCode.getPayWay().wayCode)
    console.log("正在跳转", this.$router.params)
    const that = this;
    // const parameter = {
    //     cacheToken: config.cacheToken, // 支付金额
    // };
    that.toGetPayOrder()
  },
  methods: {
      toGetPayOrder() {
          const that = this;
          console.log("正在跳转")
          getPayOrder().then(res => {
              console.log("cacheToken", config.cacheToken)
              console.log("res", res)
              location.href = res;
          }).catch(res => {
              that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
          });
      }
  }
}
</script>
<style lang="css" scoped>
  .jump {
    height:100vh;
    width: 100vw;
    display:flex;
    justify-content: center;
    align-items: center;
  }
</style>